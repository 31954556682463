import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
const PublicLayout = ({children, bg, body}) => {
  useEffect(()=>window.scrollTo(0,0))
  return (
    <div>
      <Header bg={bg} body={body} />
      {children}
      <Footer />
    </div>
  )
}

export default PublicLayout