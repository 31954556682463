import React from 'react'
import styles from './index.module.css'
import VisaLogo from '../../assets/logos/visa.png'
import MastercardLogo from '../../assets/logos/mc.png'
import UnionpayLogo from '../../assets/logos/unionpay.png'

const LogoBox = ({image, alt}) => {
  return (
    <div className={`${styles.paymentlogo} flex items-center justify-center relative payments-logo-wrapper`}>
      <img src={image} alt={alt} className='absolute h-full w-full top-0 left-0 object-contain' />
    </div>
  )
}
const Payments = () => {
  return (
    <section className='partners-section py-8 px-2 text-center'>
      <div className='ft-inner-container'>
        <h1 className='text-2xl mt-3 mb-8' style={{ color: '#082854' }}>
          Accept all payments
        </h1>
        <div className='partners-grid inline-grid grid-cols-3 gap-20 mb-6'>
          <LogoBox image={VisaLogo} alt='Visa' />
          <LogoBox image={MastercardLogo} alt='Mastercard' />
          <LogoBox image={UnionpayLogo} alt='Union Pay' />
        </div>
      </div>
    </section>
  )
}

export default Payments