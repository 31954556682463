import React from 'react'
import { Link } from 'react-router-dom'
import styles from './index.module.css'
import Logo from '../../assets/logos/kwezi_logo_light.png'

const menu = [
  {
    label: 'Home',
    path: '/',
    button: false,
    margin: 10,
    active: true
  },
  {
    label: 'Wallet',
    path: '/wallet',
    button: false,
    margin: 10,
    active: true
  },
  {
    label: 'SmartPOS',
    path: '/smartpos',
    button: false,
    margin: 10,
    active: true
  },
  {
    label: 'About',
    path: '/about',
    button: false,
    margin: 10,
    active: true
  },
  {
    label: 'Careers',
    path: '/careers',
    button: false,
    margin: 10,
    active: true
  },
  // {
  //   label: 'Login',
  //   path: '/login',
  //   button: false,
  //   margin: 10,
  //   active: true
  // },
  // {
  //   label: 'Signup',
  //   path: '/signup',
  //   button: true,
  //   margin: 0,
  //   active: true
  // }
]
const Navigation = () => {
  return (
    <nav className='py-4 px-2 text-white'>
      <div className={`flex p-1 items-center justify-between ${styles.navigation}`}>
        <Link to='/'><img src={Logo} alt='KweziPay Logo' /></Link>
        <ul className={`navigation flex items-center ${styles.menu}`}>
          {menu.map((item, idx) =>
            <li key={idx} className={`mr-${item.margin}`}>
              <Link to={item.path} className={`nav-link ${item.button ? 'kwezi-btn-link p-2' : ''} ${item.active ? '' : 'disabled'}`}>{item.label}</Link>
            </li>
          )}
        </ul>
      </div>
      <div className={`bottom ${styles.navigation}`}>
        <hr className='opacity-40 mt-5' />
      </div>
    </nav>
  )
}
const Header = ({ bg, body }) => {
  return (
    <div className={`p-2 header flex flex-col ${styles.header} cover-${bg}`}>
      <Navigation />
      {body}
    </div>
  )
}

export default Header