import React, { useState } from 'react'
import PublicLayout from '../../layouts/Public'

const WalletHeader = () => {
    
    const [inputValue, setInputValue] = useState('');

    const [inputValid, setInputValid] = useState(true);
    
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        redirectToWallet(event.target.value)
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        redirectToWallet()
        setInputValid(false)
    };

    function redirectToWallet(code) {
        if ( (code || inputValue) === "777888999" ) document.location.assign( 'https://wallet.kwezipay.com/' )
    }
    
    return (
        <div className='py-4 grow flex items-center'>
            <div className='auth-header-wrapper'>
                <div className='auth-form-wrapper bg-white rounded-md shadow p-10 text-center'>
                    <h1 className='text-2xl about-header-title mb-10 font-bold'>Wallet</h1>
                    <form className='flex flex-col' onSubmit={handleSubmit}>
                        <input value={inputValue} required className={`rounded-md py-2 px-4 text-center mb-3 border ${inputValid ? '' : 'border-red-600'}`} placeholder='Enter promo code' type='password' onChange={handleInputChange} />
                        <button className='bg-green rounded-md py-3 text-white'>Go</button>
                    </form>
                    <div className='point-to-signup w-full text-left mt-3 text-sm'>
                        <p>To go to the wallet you will need a promo code</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Wallet = () => {
    return (
        <PublicLayout bg={'blue'} body={<WalletHeader />}></PublicLayout>
    )
}

export default Wallet