import React from 'react'
import PublicLayout from '../../layouts/Public'
import Contact from '../Landing/Contact'
import Payments from '../Landing/Payments'
import AboutGraphic from '../../assets/images/about_graphic.png'
import Partners from './Partners'
const AboutHeaderBody = () => {
  return (
    <div className='py-4 grow flex items-center'>
      <div className='grid grid-cols-2 about-header-grid gap-5 align-layout'>
        <div className='relative landing-img-wrapper'>
          <img src={AboutGraphic} className='absolute top-0 left-0 h-full w-full object-contain' alt='landing page thumbnail' />
        </div>
        <div className='text text-white landing-text-wrapper flex flex-col justify-center'>
          <h1 className='text-4xl about-header-title mb-2 font-bold'>Our mission is to promote easy and safe payments for everyone</h1>
          <p className='text-base mt-3 opacity-90 leading-relaxed about-text-subtitle'>
          KweziPay is a state of the art next generation payment platform that will enable consumers and merchants to benefit from the convenience of managing one account across all activities involving money, while taking advantage of incentives to promote customer loyalty.
          </p>
        </div>
      </div>
    </div>
  )
}
const About = () => {
  return (
    <PublicLayout bg='green' body={<AboutHeaderBody />}>
      <Payments />
      <Partners />
      <Contact />
    </PublicLayout>
  )
}

export default About