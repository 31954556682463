import React from 'react'
import { FiSettings, FiLock, FiClock, FiPrinter, FiCreditCard, FiWifiOff } from 'react-icons/fi'

const Features = () => {
    const featureList = [
        {
            label: 'Easy Setup',
            description: 'Get started in minutes with our simple setup process and start accepting payments right away.',
            icon: <FiSettings className='text-2xl font-bold' color='rgb(8, 40, 84)' />
        },
        {
            label: 'Secure Transactions',
            description: 'Tap2Phone uses the latest encryption and tokenization technologies to ensure that all transactions are safe and secure.',
            icon: <FiLock className='text-2xl font-bold' color='rgb(8, 40, 84)' />
        },
        {
            label: 'Real-Time Reporting',
            description: 'Keep track of your sales and payments in real-time with our easy-to-use reporting dashboard.',
            icon: <FiClock className='text-2xl font-bold' color='rgb(8, 40, 84)' />
        },
        {
            label: 'Customizable Receipts',
            description: 'Personalize your receipts with your logo and contact information to build your brand and engage with your customers.',
            icon: <FiPrinter className='text-2xl font-bold' color='rgb(8, 40, 84)' />
        },
        {
            label: '100% Compliant',
            description: 'Tap2Phone lets you accept payments from all major credit and debit cards, as well as mobile wallets like Apple Pay and Google Pay.',
            icon: <FiCreditCard className='text-2xl font-bold' color='rgb(8, 40, 84)' />
        },
        {
            label: 'Offline Mode',
            description: 'Tap2Phone allows you to accept payments even when you don\'t have an internet connection. Simply process transactions in offline mode and they will be securely stored until you\'re back online.',
            icon: <FiWifiOff className='text-2xl font-bold' color='rgb(8, 40, 84)' />
        },
    ]
    return (
        <section className='features-section py-8 px-2 text-center'>
            <div className='ft-inner-container'>
                <h1 className='text-2xl mt-3 mb-5' style={{ color: '#082854' }}>
                    The Best Contactless Payment Solution
                </h1>
                <p className='max-w-xl ml-auto mr-auto text-gray-600'>Tap2Phone is packed with features that make it the ultimate choice for businesses of all sizes looking for a fast, secure and convenient way to accept payments.</p>
                <div className='grid grid-cols-3 gap-2 mt-8'>
                    {featureList.map(item =>
                        <FeatureItem
                            key={item.idx}
                            icon={item.icon}
                            label={item.label}
                            description={item.description}
                        />
                    )}
                </div>
            </div>
        </section>
    )
}

const FeatureItem = ({ label, icon, description }) => {
    return (
        <div className='text-center p-3'>
            <div className='shadow features-icon-wrapper rounded-md bg-white flex items-center justify-center'>
                {icon}
            </div>
            <h6 className='mt-2'>{label}</h6>
            <p className='mt-2 opacity-70'>{description}</p>
        </div>
    )
}
export default Features
