import React from 'react'
import styles from './index.module.css'

// const numberslist = [
//   {
//     title: 'ISO',
//     description: 'ISO-19943 grade security compliant'
//   },
//   {
//     title: '3.2',
//     description: 'Users around the world'
//   },
//   {
//     title: '20M',
//     description: 'Transactions'
//   },
//   {
//     title: '#3',
//     description: 'Ranked in Africa'
//   },
//   {
//     title: '10+',
//     description: 'Merchant integrations'
//   },
//   {
//     title: '5K+',
//     description: 'Developer APIs'
//   },
// ]
const numberslist = [
  {
    title: '#1',
    description: 'Customer Service'
  },
  {
    title: '2',
    description: 'Days onboarding'
  },
  {
    title: '30+',
    description: 'Developers'
  }
]
const NumberItem = ({item: {title, description}}) => {
  return (
    <div className='flex numbers-item flex-col text-center items-center justify-center py-6'>
      <h1 className='font-bold text-5xl'>{title}</h1>
      <p className='text-base'>{description}</p>
    </div>
  )
}
const Numbers = () => {
  return (
    <section className={`${styles.numberswrapper} py-6 numbers-section px-2 text-center text-white flex items-center`}>
      <div className='ft-inner-container'>
        <h1 className='text-2xl font-bold mt-3 mb-5'>
          Why companies trust KweziPay
        </h1>
        <button className={styles.numbersbtn}>Contact sales to get a demo</button>
        <div className='numbers-list py-4 mt-8 grid grid-cols-3 gap-4 md:gap-20'>
          {numberslist.map((item, idx)=> <NumberItem key={idx} item={item} />)}
        </div>
      </div>
    </section>
  )
}

export default Numbers