import React from 'react'
import PublicLayout from '../../layouts/Public'
import CareersGraphic from '../../assets/images/careers.svg'

const CareersHeaderBody = () => {
  return (
    <div className='py-4 grow flex items-center'>
      <div className='grid grid-cols-2 about-header-grid gap-5 align-layout'>
        <div className='relative landing-img-wrapper'>
          <img src={CareersGraphic} className='absolute top-0 left-0 h-full w-full object-contain' alt='landing page thumbnail' />
        </div>
        <div className='text text-white landing-text-wrapper flex flex-col justify-center'>
          <h1 className='text-4xl about-header-title mb-2 font-bold'>Want to be part of the digital future?</h1>
          <p className='text-base text-white mt-3 opacity-90 leading-relaxed about-text-subtitle'>
          No open vacancies, please check back later
          </p>
        </div>
      </div>
    </div>
  )
}

const Careers = () => {
  return (
    <PublicLayout bg='yellow' body={<CareersHeaderBody />}>

    </PublicLayout>
  )
}

export default Careers