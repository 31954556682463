import React from 'react'
import PublicLayout from '../../layouts/Public'
import Tap2PhoneGraphic from '../../assets/images/smartpos_phone.png'
import Payments from '../Landing/Payments'
import CallToAction from './CallToAction'
import Banner from './Banner'
import Features from './Features'

const Tap2PhoneHeaderBody = () => {
    return (
      <div className='tap2phone-section py-4 grow flex items-center'>
        <div className='grid grid-cols-2 about-header-grid gap-5 align-layout'>
          <div className='text text-white landing-text-wrapper flex flex-col justify-center'>
            <h1 className='text-4xl about-header-title mb-2 font-bold'>Introducing SmartPOS - The Ultimate Contactless Payment Solution</h1>
            <p className='text-base text-white mt-3 opacity-90 leading-relaxed about-text-subtitle'>
            Say goodbye to bulky card machines and hello to SmartPOS - the revolutionary payment solution that lets you accept payments using just your phone.
            </p>
          </div>
          <div className='relative landing-img-wrapper'>
            <img src={Tap2PhoneGraphic} className='absolute top-0 left-0 h-full w-full object-contain' alt='landing page thumbnail' />
          </div>
        </div>
      </div>
    )
  }

const Tap2Phone = () => {
  return (
    <PublicLayout bg={'purple'} body={<Tap2PhoneHeaderBody />}>
      <Payments />
      <Banner />
      <Features />
      <CallToAction />
    </PublicLayout>
  )
}

export default Tap2Phone
