import React from 'react'
import Logo from '../../assets/logos/kwezi_logo_dark.png'
import styles from './index.module.css'
import FbIcon from '../../assets/icons/facebook.png'
import IgIcon from '../../assets/icons/instagram.png'
import TwIcon from '../../assets/icons/twitter.png'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className='py-10 px-4'>
      <div className={`grid grid-cols-5 gap-4 bg-white footer-wrapper ${styles.footer}`}>
        <div className='mr-10'>
          <img src={Logo} alt='kwezipay logo' />
        </div>
        <div>
          <h1 className={`${styles.heading} mb-4`}>Company</h1>
          <ul>
            <li className={`leading-10 ${styles.listitem}`}>
              <Link to='/about'>About</Link>
            </li>
            <li className={`leading-10 ${styles.listitem}`}>
              <Link to='/careers'>Careers</Link>
            </li>
          </ul>
        </div>
        <div>
          <h1 className={`${styles.heading} mb-4`}>Contact</h1>
          <ul>
            <li className={`leading-10 ${styles.listitem}`}>Help/FAQs</li>
            <li className={`leading-10 ${styles.listitem}`}>Press</li>
            <li className={`leading-10 ${styles.listitem}`}>Affiliates</li>
          </ul>
        </div>
        <div>
          <h1 className={`${styles.heading} mb-4`}>More</h1>
          <ul>
            <li className={`leading-10 ${styles.listitem}`}>
              <Link to='/about'>About</Link>
            </li>
            <li className={`leading-10 ${styles.listitem}`}>
              <Link to='/careers'>Careers</Link>
            </li>
          </ul>
        </div>
        <div>
          <div className='social-icons flex justify-start'>
            <a href='/'>
              <img src={FbIcon} alt='Facebook Icon' />
            </a>
            <a href='/'>
              <img src={IgIcon} alt='Facebook Icon' />
            </a>
            <a href='/'>
              <img src={TwIcon} alt='Facebook Icon' />
            </a>
          </div>
          {/* <div className='downloads'>
            <h5>Try our app</h5>
          </div> */}
        </div>
      </div>
      <div className={`${styles.footer} bottom`}>
        <hr className='mt-5' />
        <p className='opacity-50 mt-10 text-center text-xs'>All rights reserved &copy; kwezipay.com</p>
      </div>
    </footer>
  )
}

export default Footer