import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './pages/About';
import Landing from './pages/Landing';
import Careers from './pages/Careers'
import Login from './pages/Login'
import Signup from './pages/Signup'
import Wallet from './pages/Wallet'
import Tap2Phone from './pages/Tap2Phone';

function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route
            path='/'
            element={<Landing />}
          />
          <Route
            path='/smartpos'
            element={<Tap2Phone />}
          />
          <Route
            path='/about'
            element={<About />}
          />
          <Route
            path='/careers'
            element={<Careers />}
          />
          <Route
            path='/login'
            element={<Login />}
          />
          <Route
            path='/signup'
            element={<Signup />}
          />
          <Route
            path='/wallet'
            element={<Wallet />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
