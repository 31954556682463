import React from 'react'
import styles from './index.module.css'

const ContactForm = () => {
  return (
    <form className='contact-form' onSubmit={e=>e.preventDefault()}>
      <div className='form-group flex'>
        <input 
          type='email' 
          className={styles.cinput}
          placeholder='Enter email address'
        />
        <button className={`${styles.cbtn} ml-2`}>Send</button>
      </div>
    </form>
  )
}
const Contact = () => {
  return (
    <section className={`py-6 px-2 text-center contact-section flex items-center ${styles.contactwrapper}`}>
      <div className='ft-inner-container'>
        <h1 className='font-bold text-3xl mt-3 mb-6' style={{ color: '#082854' }}>
          Do you have any questions?
        </h1>
        <p>Enter your email to get started</p>
        <div className='form-wrapper mt-5'>
          <ContactForm />
        </div>
      </div>
    </section>
  )
}

export default Contact