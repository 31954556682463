import React from 'react'
import PublicLayout from '../../layouts/Public'
import {Link} from 'react-router-dom'
const LoginHeader = () => {
    return (
        <div className='py-4 grow flex items-center'>
            <div className='auth-header-wrapper'>
                <div className='auth-form-wrapper bg-white rounded-md shadow p-10 text-center'>
                    <h1 className='text-2xl about-header-title mb-10 font-bold'>Sign in to KweziPay</h1>
                    <form className='flex flex-col'>
                        <input className='rounded-md py-2 px-4 text-center mb-3 border' placeholder='Enter email' type='email' />
                        <input className='rounded-md py-2 px-4 text-center mb-3 border' placeholder='Enter password' type='password' />
                        <button className='bg-green rounded-md py-3 text-white'>Login</button>
                    </form>
                    <div className='point-to-signup w-full text-left mt-3 text-sm'>
                        <p>New to KweziPay? <Link to='/signup' className='text-blue-700'>Signup</Link></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Login = () => {
    return (
        <PublicLayout bg={'blue'} body={<LoginHeader />}></PublicLayout>
    )
}

export default Login