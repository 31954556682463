import React from 'react'
import Steam from '../../assets/logos/steam.svg'
import Gaijin from '../../assets/logos/gaijin.svg'
import BlackDesert from '../../assets/logos/black_desert.svg'
import styles from './index.module.css'

const LogoBox = ({image, alt, invertColor}) => {
    return (
      <div className={`${styles.partnerlogo} flex items-center justify-center relative partner-logo-wrapper`}>
        <img src={image} alt={alt} className={`absolute h-full w-full top-0 left-0 object-contain ${invertColor ? 'invert' : ''}`} />
      </div>
    )
  }
  const Partners = () => {
    return (
      <section className='partners-section py-8 px-2 text-center'>
        <div className='ft-inner-container'>
          <h1 className='text-2xl mt-3 mb-8' style={{ color: '#082854' }}>
            Trusted by companies all over the world
          </h1>
          <div className='partners-grid inline-grid grid-cols-3 gap-10 lg:gap-20 mb-6'>
            <LogoBox image={Steam} alt='Steam' invertColor={true} />
            <LogoBox image={Gaijin} alt='Gaijin' invertColor={true} />
            <LogoBox image={BlackDesert} alt='Black Desert' invertColor={true} />
          </div>
        </div>
      </section>
    )
  }
  
  export default Partners;