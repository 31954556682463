import React from 'react'
const CallToAction = () => {
  return (
    <div className="py-10 h-80 flex items-center flex-col justify-center bg-gray-100 text-700 text-center">
      <div className="text-black font-bold mb-3 text-lg uppercase">Get in Touch with Tap2Phone Today</div>
      <p className='max-w-xl opacity-70 px-2'>Have questions about Tap2Phone or want to learn more about how our contactless payment solution can benefit your business? Our team is here to help!</p>
      <button className="mt-3 font-bold px-5 py-3 p-button-raised p-button-rounded kwezi-landing-link  white-space-nowrap">Contact Us</button>
    </div>

  )
}

export default CallToAction
