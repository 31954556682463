import React from 'react'
import PublicLayout from '../../layouts/Public'
import LandingImage from '../../assets/images/home-payment.png'
import Features from './Features'
import Payments from './Payments'
import Presence from './Presence'
import Numbers from './Numbers'
import Contact from './Contact'
const LandingHeaderBody = () => {
  return (
    <div className='py-4 grow flex items-center'>
      <div className='grid landing-header-grid grid-cols-2 gap-5 align-layout'>
        <div className='text text-white landing-text-wrapper flex flex-col justify-center'>
          <h1 className='text-6xl mb-2 landing-header-title font-bold'>Africa's Next Generation Payment Platform</h1>
          <p className='text-base mt-5 opacity-70 landing-text-subtitle'>
            Africa has embraced the digital economy. The next step is to ensure its payment infrastructure keeps up with new trends and technologies.</p>
          <a href='/' className='kwezi-landing-link text-base flex items-center mt-8 justify-center' rel='noreferrer noopener'>Request demo</a>
        </div>
        <div className='relative landing-img-wrapper'>
          <img src={LandingImage} className='absolute top-0 left-0 h-full w-full object-contain' alt='landing page thumbnail' />
        </div>
      </div>
    </div>
  )
}
const Landing = () => {
  return (
    <PublicLayout bg='blue' body={<LandingHeaderBody />}>
      <Features />
      <Payments />
      <Presence />
      <Numbers />
      <Contact />
    </PublicLayout>
  )
}

export default Landing