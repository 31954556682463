import React from 'react'

const Banner = () => {
  return (
    <div className='mt-3 relative tap2phone-banner'>
     <img src='/tap2phone_illust.jpg' className='h-full w-full left-0 top-0 absolute object-cover object-bottom' alt='tap2phone illustration' />
    </div>
  )
}

export default Banner
