import React from 'react'
import { ShoppingCart, ArrowLeftRight, Banknote } from 'lucide-react'
const featureslist = [
  {
    title: 'Payment Checkouts',
    description: 'A modern, and intuitive checkout that can be tailored to your specific requirements.',
    icon: <ShoppingCart color='white' />,
    background: '#2EB783',
    mt: 4
  },
  {
    title: 'Accept Payments',
    description: 'A payment gateway that allows merchants and consumers to make online payments seamlessly.',
    icon: <ArrowLeftRight color='white' />,
    background: '#3A64FC',
    mt: 2
  },
  {
    title: 'Transfers',
    description: 'Get your money in the shortest time in the region.',
    icon: <Banknote color='white' />,
    background: '#FF6C76',
    mt: 4
  },
]

const FeatureCard = ({ feature: { title, description, icon, background, mt } }) => {
  return (
    <article className={`flex feature-card py-6 px-4 items-center text-center justify-center flex-col mt-${mt} rounded-md`}>
      <div className='icon rounded-full flex items-center justify-center' style={{ background, height: '48px', width: '48px' }}>
        {icon}
      </div>
      <h5 className='mt-3 text-lg'>{title}</h5>
      <p className='text-base opacity-70 mt-1 leading-5'>{description}</p>
    </article>
  )
}
const Features = () => {
  return (
    <section className='features-section py-6 px-2 text-center'>
      <div className='ft-inner-container'>
        <h1 className='font-bold text-3xl mt-3 mb-8' style={{color: '#082854'}}>Simple and safe payment solutions</h1>
        <div className='features-list grid gap-4 grid-cols-3 mt-4'>
          {featureslist.map((item, idx) => <FeatureCard idx={idx} feature={item} />)}
        </div>
      </div>
    </section>
  )
}

export default Features