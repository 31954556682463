import React from 'react'
import styles from './index.module.css'
import GlobeImage from '../../assets/images/global-image.png'
const Presence = () => {
  return (
    <section className='presence-section'>
      <div className={`presence-wrapper ft-inner-container relative ${styles.globewrapper}`}>
        <img src={GlobeImage} alt='presence of kwezi pay' className='absolute h-full w-full top-0 left-0 object-contain'/>
      </div>
    </section>
  )
}

export default Presence